import request from "@/request/request-utils";

export default {
  // 登录
  login: (params) => request.getAction("/api/Default/Login", params),
  // 登出
  logout: () => request.getAction("/api/Default/Logout"),
  // 获取当前用户信息
  currentAccount: () => request.getAction("/api/Seller/GetMyData"),
  // 修改密码
  editPassword: (params) =>
    request.getAction("/api/Seller/EditPassword", params),
  // 设置收款
  paymentCode: (data) => request.postData("/api/Seller/SetPaymentCode", data),
  // 文章内容
  articleContent: (params) =>
    request.getAction("/api/Seller/GetArticleContent", params),

  // 获取个人邮箱详情
  getEmailInfo: () => request.getAction("/api/Seller/GetMyEmailInfo"),
  // 获取邮箱验证码
  sendEmailCode: (params) =>
    request.getAction("/api/Default/SendEmailVerifyCode", params),
  // 设置邮箱
  setEmail: (params) => request.getAction("/api/Seller/SetEmail", params),
};
