<template>
  <div
    :class="`common-button button-type--${type} button-size--${size} ${
      plain ? 'is-plain' : ''
    } ${disabled ? 'is-disabled' : ''}`"
    @click="onClickCommonButton"
  >
    <div class="button-content">
      <img v-if="icon" class="button-icon" :src="icon" />
      <span><slot></slot></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonButton",
  props: {
    type: {
      type: String,
      default: "normal",
    },
    size: {
      type: String,
      default: "default",
    },
    icon: {
      type: String,
      default: "",
    },
    plain: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickCommonButton(e) {
      if (this.$listeners.click && !this.disabled) {
        this.$emit("click", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common-button {
  display: flex;
  justify-content: center;
  border-radius: $app-dimen-radius;
  cursor: pointer;
}

.common-button + .common-button {
  margin-left: 10px;
}

.button-content {
  display: flex;
  align-items: center;
}

.button-icon {
  width: 13px;
}

.button-icon + span {
  margin-left: 5px;
  white-space: nowrap;
}

.is-plain {
  border: 1px solid $app-color-border;
  background-color: white !important;
}

.is-plain:hover,
.is-plain:focus {
  background-color: #e6e6e6 !important;
}

.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-type--text {
  background-color: transparent;
  color: $app-color-primary;

  .button-content {
    span {
      text-decoration: underline;
    }
  }
}

.button-type--text.is-disabled {
  background-color: transparent !important;
}

.button-type--text:hover,
.button-type--text:focus {
  opacity: 0.7;
}

.button-type--normal {
  background-color: #e9f2ff;
  color: $app-color-text;
}

.button-type--normal.is-disabled {
  background-color: #e9f2ff !important;
}

.button-type--normal:hover,
.button-type--normal:focus {
  background-color: #cce4ff;
}

.button-type--primary {
  color: white;
  background: linear-gradient(
    to right,
    $app-color-primary 0px,
    $app-color-light 100%
  );
}

.button-type--primary.is-disabled {
  background: linear-gradient(
    to right,
    $app-color-primary 0px,
    $app-color-light 100%
  ) !important;
}

.button-type--primary:hover,
.button-type--primary:focus {
  background: linear-gradient(to right, #3183db 0px, #37cad1 100%);
}

.button-type--success {
  color: white;
  background: linear-gradient(to right, $app-color-success 0px, #66ff99 100%);
}

.button-type--success.is-disabled {
  background: linear-gradient(
    to right,
    $app-color-success 0px,
    #66ff99 100%
  ) !important;
}

.button-type--success:hover,
.button-type--success:focus {
  background: linear-gradient(to right, #2aab2a 0px, #33ff33 100%);
}

.button-type--warning {
  color: white;
  background: linear-gradient(to right, $app-color-warning 0px, #ebbf92 100%);
}

.button-type--warning.is-disabled {
  background: linear-gradient(
    to right,
    $app-color-warning 0px,
    #fbcd9e 100%
  ) !important;
}

.button-type--warning:hover,
.button-type--warning:focus {
  background: linear-gradient(to right, #d47f00 0px, #ffcc33 100%);
}

.button-type--danger {
  color: white;
  background: linear-gradient(to right, $app-color-error 0px, #ff99ff 100%);
}

.button-type--danger.is-disabled {
  background: linear-gradient(
    to right,
    $app-color-error 0px,
    #ff99ff 100%
  ) !important;
}

.button-type--danger:hover,
.button-type--danger:focus {
  background: linear-gradient(to right, #ff0066 0px, #ff33ff 100%);
}

.button-size--default {
  padding: 12px 20px;
  font-size: 14px;

  .button-content {
    height: 16px;
  }
}

.button-size--default.is-plain {
  padding: 11px 19px;
}

.button-size--medium {
  padding: 10px 20px;
  font-size: 14px;

  .button-content {
    height: 16px;
  }
}

.button-size--medium.is-plain {
  padding: 9px 19px;
}

.button-size--small {
  padding: 9px 15px;
  font-size: 12px;

  .button-content {
    height: 14px;
  }
}

.button-size--small.is-plain {
  padding: 8px 14px;
}

.button-size--mini {
  padding: 7px 15px;
  font-size: 12px;

  .button-content {
    height: 14px;
  }
}

.button-size--mini.is-plain {
  padding: 6px 14px;
}
</style>
