import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    windowWidth: 0,
    windowSize: 5,

    currentAccount: {},

    // 用户店铺
    sellerShops: [],

    // 字典
    baseData: [],
    productCategory: [],

    accountBalance: 0,
    accountVouchers: 0,

    isPc: "",
  },
  getters: {},
  mutations: {
    windowWidth(state, windowWidth) {
      state.windowWidth = windowWidth;
    },
    windowSize(state, windowSize) {
      state.windowSize = windowSize;
    },

    currentAccount(state, currentAccount) {
      state.currentAccount = currentAccount;
    },

    sellerShops(state, sellerShops) {
      state.sellerShops = sellerShops;
    },

    baseData(state, baseData) {
      state.baseData = baseData;
    },
    productCategory(state, productCategory) {
      state.productCategory = productCategory;
    },

    accountBalance(state, accountBalance) {
      state.accountBalance = accountBalance;
    },
    accountVouchers(state, accountVouchers) {
      state.accountVouchers = accountVouchers;
    },

    isPC(state, isPC) {
      state.isPC = isPC;
    },
  },
  actions: {},
  modules: {},
});
