import axios from "axios";
import { Message } from "element-ui";

import router from "@/router";
import store from "@/store";

class HttpRequest {
  baseUrl = "";
  // 构造函数，数据访问的baseUrl
  constructor(
    baseUrl = process.env.VUE_APP_ENV === "production"
      ? window.location.origin
      : process.env.VUE_APP_BASE_API
  ) {
    // process.env.VUE_APP_SERVER
    this.baseUrl = baseUrl;
  }
  /**
   * 返回内部的配置
   */
  getInsideConfig() {
    var config = {
      baseURL: this.baseUrl,
      headers: {
        "Cache-Control": "no-cache",
      },
      cache: false,
    };
    return config;
  }
  /**
   * 请求拦截方法
   * @param {*} instance
   */
  interceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      (config) => {
        if (config.url.indexOf("/Login") === -1) {
          // 不是登录接口
          config.headers["Authorization"] =
            localStorage.getItem("AccountToken");
        }
        return config; // 返回config使得请求能够继续
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截 及服务端返回时的拦截
    instance.interceptors.response.use(
      (res) => {
        if (res.status === 200) {
          return res;
        } else {
          Message({ message: res, type: "error" });
        }
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("AccountToken");

          if (store.state.isPC === true) {
            router
              .push({
                path: "/bs/account/login",
              })
              .catch(() => {});
          } else if (store.state.isPC === false) {
            router
              .push({
                path: "/bs/h5/account/login",
              })
              .catch(() => {});
          }
        }
        return Promise.reject(error);
      }
    );
  }
  /**
   * 创建并调用请求
   * @param {*} options
   */
  request(options) {
    // axios实例
    var instance = axios.create();
    // 如果有相同的属性名 它会使用后边的对象的属性进行覆盖
    options = Object.assign(this.getInsideConfig(), options);
    if (!options.params) {
      options.params = {};
    }

    // options.params.timestampie = new Date().getTime() // 设置时间戳
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

export default HttpRequest;
