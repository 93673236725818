import request from "@/request/request-utils";

export default {
  // 主任务列表
  taskMain: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskPageList", params),
  // 子任务列表
  taskChild: (data) =>
    request.postJson("/api/Task/GetTaoBaoTaskChildPageList", data),
  // 子任务应返时间
  taskWaitRefund: (data) =>
    request.postJson("/api/Task/GetTaoBaoTaskChildWaitRefundPageList", data),
  // 任务发布
  taskPublish: (data) => request.postData("/api/Task/TaoBaoTaskPublish", data),
  // 任务快速编辑发布
  taskFastEditPublish: (data) =>
    request.postData("/api/Task/FastTaoBaoTaskEditPublish", data),
  // 任务详情
  taskDetail: (params) => request.getAction("/api/Task/GetTaoBaoTask", params),
  // 任务取消
  taskCancel: (params) =>
    request.getAction("/api/Task/TaoBaoTaskCancel", params),
  // 任务取消记录
  taskCancelLog: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskCancelLogList", params),
  // 任务取消列表
  taskCancelList: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskCancelLogPageList", params),
  // 任务账单明细
  taskBill: (params) =>
    request.getAction("/api/Task/GetTaoBaoTaskChildBillList", params),
  // 任务评价
  taskEvaluate: (data) =>
    request.postJson("/api/Task/GetTaoBaoTaskEvaluatePageList", data),
  // 设置任务描述
  taskDescribe: (params) =>
    request.getAction("/api/Task/SetTaoBaoTaskDescribe", params),
  // 设置收款码无效
  paymentInvalid: (params) =>
    request.getAction("/api/Task/SetPaymentCodeInvalid", params),
  // 返款
  paymentReturn: (params) =>
    request.getAction("/api/Task/TaoBaoTaskReturn", params),
  // 银行卡批量返款
  bankBatchReturn: (data) =>
    request.postJson("/api/Task/BatchBankBatchReturn", data),
  // 支付宝批量返款
  alipayBatchReturn: (data) =>
    request.postJson("/api/Task/ZhiFuBaoAccountBatchReturn", data),
  // 任务快速发布
  taskFastPublish: (params) =>
    request.getAction("/api/Task/FastTaoBaoTaskPublish", params),
  // 任务批量发布
  taskBatchPublish: (data) =>
    request.postJson("/api/Task/BatchTaoBaoTaskPublish", data),
  // 任务批量完结
  taskBatchFinish: (data) =>
    request.postJson("/api/Task/BatchTaoBaoTaskDone", data),
  // 任务数量
  taskChildTotal: () =>
    request.getAction("/api/Task/GetTaoBaoTaskChildSellerTotal"),
  // 设置任务评价
  setTaskEvaluate: (data) =>
    request.postData("/api/Task/SetTaoBaoTaskEvaluate", data),
  // 获取任务加价收益
  markupProfit: (params) =>
    request.getAction("/api/Task/GetMarkUpProfit", params),
  // 任务批量取消
  taskBatchCancel: (data) =>
    request.postJson("/api/Task/TaoBaoTaskCancelBatch", data),
  // 发布任务模板下载
  downloadPublishTemplate: () =>
    request.getFile("/api/Task/GetTaoBaoTaskPublishExcelTemplate"),
  // 模板发布任务
  taskTemplatePublish: (data) =>
    request.postDataForFile("/api/Task/TaoBaoTaskPublishByExcel", data),
  // 店铺可用货返额度
  shopHFQuota: (params) =>
    request.getAction("/api/Task/GetTaoBaoShopHuoFanQuotaUsable", params),
};
