import request from "@/request/request-utils";

export default {
  // 余额数据
  balanceData: (params) => request.getAction("/api/Seller/GetBalance", params),
  // 余额转售发布
  balanceRelease: (params) =>
    request.getAction("/api/UserBalanceSell/Release", params),
  // 余额转售购买
  balanceBuy: (params) => request.getAction("/api/UserBalanceSell/Buy", params),
  // 余额转售支付
  balancePay: (data) => request.postData("/api/UserBalanceSell/Pay", data),
  // 余额转售取消
  balanceCancel: (params) =>
    request.getAction("/api/UserBalanceSell/Cancel", params),
  // 余额转售者收款码
  balancePayment: (params) =>
    request.getAction("/api/UserBalanceSell/GetSellPaymentInfo", params),
  // 余额转售者收款码无效
  balancePaymentInvalid: (params) =>
    request.getAction("/api/UserBalanceSell/SetSellPaymentInvalid", params),
  // 余额转售完成
  balanceDone: (params) =>
    request.getAction("/api/UserBalanceSell/Done", params),
  // 余额待售列表
  balanceSelling: (params) =>
    request.getAction("/api/UserBalanceSell/GetSellingPageList", params),
  // 余额变更记录
  balanceChangeRecord: (params) =>
    request.getAction("/api/Seller/GetBalanceChangePageList", params),
  // 余额转售记录
  balanceResaleRecord: (params) =>
    request.getAction("/api/UserBalanceSell/GetMySellPageList", params),
  // 余额购买记录
  balanceBuyRecord: (params) =>
    request.getAction("/api/UserBalanceSell/GetMyBuyPageList", params),
};
