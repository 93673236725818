import request from "@/request/request-utils";

export default {
  // 店铺字典
  shop: () => request.getAction("/api/Seller/GetTaoBaoShopView"),
  // 商品字典
  product: (params) =>
    request.getAction("/api/Seller/GetTaoBaoProductView", params),
  // 字典
  baseData: () => request.getAction("/api/Seller/GetBaseData"),
};
