export default {
  /**
   * 解析地址携带的参数
   * @param {*} url 需要解析的地址
   * @returns 地址携带参数集合的对象
   */
  formatUrlQuery(url) {
    if (url.indexOf("?") > -1) {
      var querys = url.split("?")[1].split("&");
      if (querys.length > 0) {
        var result = {};
        querys.forEach((item) => {
          var qs = item.split("=");
          result[qs[0]] = qs[1];
        });
        return result;
      } else {
        return {};
      }
    } else {
      return {};
    }
  },

  /**
   * 根据规范格式化日期
   * @param {*} date 需要格式化的日期
   * @param {*} fmt 格式化的规范
   * @returns 格式化后的日期
   */
  dateFormat: function (date, fmt) {
    if (!date) {
      return;
    }
    if (typeof date == "string") {
      date = new Date(date);
    }
    var o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  },
  /**
   * 计算两个日期间间隔的月数
   * @param {*} date1 开始日期
   * @param {*} date2 结束日期
   * @returns 间隔月数
   */
  monthNumber: function (date1, date2) {
    let dateOne = new Date(date1);
    let dateTwo = new Date(date2);
    // 第一个日期的年和月
    let yearOne = dateOne.getFullYear();
    let monthOne = dateOne.getMonth() + 1;
    // 第二个日期的年和月
    let yearTwo = dateTwo.getFullYear();
    let monthTwo = dateTwo.getMonth() + 1;
    // 两个日期的月份数
    let oneMonthNum = yearOne * 12 + monthOne;
    let twoMonthNum = yearTwo * 12 + monthTwo;
    return Math.abs(oneMonthNum - twoMonthNum);
  },
  /**
   * 计算两个日期间间隔的天数
   * @param {*} date1 开始日期
   * @param {*} date2 结束日期
   * @returns 间隔天数
   */
  dateNumber: function (date1, date2) {
    // 计算两个日期之间的差值
    let dateOne = new Date(date1);
    let dateTwo = new Date(date2);
    // 将两个日期都转换为毫秒格式，然后做差
    let diffDate = Math.abs(dateOne - dateTwo); // 取相差毫秒数的绝对值
    let diffDay = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
    return diffDay;
  },
  /**
   * 浮点数相加之和错误
   * @param {*} arg1 加数
   * @param {*} arg2 加数
   * @returns 和
   */
  floatAdd: function (arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
  },

  // 判断是否是PC端
  isPC() {
    var userAgentInfo = navigator.userAgent;
    var agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    var flag = true;
    for (var i = 0; i < agents.length; i++) {
      if (userAgentInfo.indexOf(agents[i]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },

  /**
   * 判断文本是否为空
   * @param {*} text 需要判断的文本
   * @returns true 文本为空 false 文本不为空
   */
  isEmptyText: function (text) {
    if (!text || text === "") {
      return true;
    }
    return false;
  },

  deepClone(obj) {
    const objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === "object") {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          // 用for...in循环会获取到原型链上的可枚举属性，不过可以使用hasOwnProperty()方法过滤掉。
          if (obj[key] && typeof obj[key] === "object") {
            if (obj[key] instanceof File) {
              objClone[key] = obj[key];
            } else {
              // 判断ojb子元素是否存在并且是否为为对象，如果是，递归复制
              objClone[key] = this.deepClone(obj[key]);
            }
          } else {
            // 如果不是，简单复制
            objClone[key] = obj[key];
          }
        }
      }
    }
    return objClone;
  },
};
