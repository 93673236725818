import request from "@/request/request-utils";

export default {
  // 上传文件
  uploadFile: (data) => request.postFile("/api/Default/UploadTempFiles", data),
  // 下载文件
  downloadFile: (url) => request.getFile(url),
  // 下载返款文件
  downloadRefundFile: (data) =>
    request.postDataForFile(
      "/api/Task/GetTaoBaoTaskChildWaitRefundExport",
      data
    ),
  // 上传返款文件
  uploadRefundFile: (data) =>
    request.postFile("/api/Task/TaoBaoTaskReturnFromFile", data),
};
